import React from 'react';
import slugify from 'slugify';
import {graphql, Link} from 'gatsby';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Button from '../components/Button';
import * as style from '../assets/style/pages/tabs-page.module.scss';

const OfferPage = ({location, data}) => {
  const offers = data.offers.edges;
  const offer = data.offer.edges.map(({node}) => node)[0];
  const firstOfferSlug = slugify(offers[0].node.title.value, {lower: true});
  const offerSlug = slugify(offer.title.value, {lower: true});
  const realizationCategories = data.categories.edges.map(({node}) => slugify(node.title.value, {lower: true}));
  const productsCategories = data.products.edges
    .map(({node}) => slugify(node.category.value.title.value, {lower: true}))
    .reduce((array, item) => (array.includes(item) ? array : [...array, item]), []);

  const crumbs = [
    {title: 'Strona główna', url: '/'},
    {title: 'Oferta', url: `/oferta/${firstOfferSlug}`},
    {title: offer.title.value, url: `/oferta/${offerSlug}`}
  ];

  return (
    <Layout>
      <Seo title={offer.title.value}>
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(
                ({title, url}, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `
              )}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={style.tabs}>
        <div className='container'>
          <div className='row'>
            <div className={`${style.tabs__navColumn} col-12 col-md-3 offset-xl-1`}>
              <strong className={style.tabs__navTitle}>Oferta</strong>
              <ul className={style.tabs__nav}>
                {offers.map(({node}) => (
                  <li key={node.id}>
                    <Link to={`/oferta/${slugify(node.title.value, {lower: true})}`} activeClassName="active">{node.title.value}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`${style.tabs__contentColumn} col-12 col-md-9 col-xl-8`}>
              <div className={style.tabs__content}>
                <h1>{offer.title.value}</h1>
                <div dangerouslySetInnerHTML={{__html: offer.description.value}}></div>
                <div className={style.tabs__buttonsGroup}>
                  {productsCategories.includes(slugify(offer.title.value, {lower: true})) && (
                    <Button
                      as={Link}
                      to={`/oferta/${slugify(offer.title.value, {lower: true})}/katalog`}
                      variant='primary'
                      color='#FFFFFF'
                    >
                      Lista produktów
                    </Button>
                  )}
                  {realizationCategories.includes(slugify(offer.title.value, {lower: true})) && (
                    <Button
                      as={Link}
                      to='/realizacje'
                      state={{category: slugify(offer.title.value, {lower: true})}}
                      variant='border'
                      color='#555555'
                      contrast='#FFFFFF'
                    >
                      Realizacje
                    </Button>
                  )}
                </div>
                {(offer.descriptionExtension?.value && <div dangerouslySetInnerHTML={{__html: offer.descriptionExtension.value}}></div>)}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    offer: allCockpitOffer(filter: {id: {eq: $id}}) {
      edges {
        node {
          title {
            value
          }
          description {
            value
          }
          descriptionExtension {
            value
          }
        }
      }
    }
    offers: allCockpitOffer {
      edges {
        node {
          id
          title {
            value
          }
        }
      }
    }
    categories: allCockpitRealizationCategories {
      edges {
        node {
          title {
            value
          }
        }
      }
    }
    products: allCockpitProducts {
      edges {
        node {
          category {
            value {
              title {
                value
              }
            }
          }
        }
      }
    }
  }
`;

export default OfferPage;
